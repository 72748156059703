<template>
  <div class="form-item">
    <div class="item">
      <div class="label">{{$t('Main business destination')}}：
        <div class="ps">{{$t('Please select the country that provides the main service')}}</div>
      </div>
      <div class="ipt-wrap">
        <el-select class="select" filterable multiple size="small" placeholder="" v-model="country">
          <el-option v-for="item in countryOptions"
                     :key="item.id"
                     :label="item.name"
                     :value="item.code">
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="item">
      <div class="label">{{$t('System Language')}}</div>
      <div class="ipt-wrap">
        <el-select class="select" v-model="language" size="small" placeholder="">
          <el-option v-for="item in languageOptions"
                     :key="item.id"
                     :label="item.name"
                     :value="item.code">
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="item">
      <div class="label">{{$t('Payment Currency (cannot be modified once selected)')}}</div>
      <div class="ipt-wrap">
        <el-select class="select" v-model="currency" size="small" placeholder="">
          <el-option v-for="item in currencyOptions"
                     :key="item.id"
                     :label="item.name"
                     :value="item.code">
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="actions">
      <button class="button m-r" @click="$emit('update:activeTabName', 'business')">
        {{$t('PreviousStep')}}
      </button>
      <button class="button" @click="onNext">{{$t('NextStep')}}</button>
    </div>
  </div>
</template>
<script>
  import {getDictList} from '../../../../api/admin-common';
  import {scrollToY} from '../../../../utils';

  export default {
    name: 'ProductsServices',
    props: {
      userService: Object,
      activeTabName: String,
      countryOptions: Array
    },
    data() {
      return {
        country: [],
        languageOptions: [],
        language: '',
        currencyOptions: [],
        currency: ''
      };
    },
    created() {
      this.getDicts();
    },
    methods: {
      getDicts() {
        getDictList('systemLanguage').then(res => {
          this.languageOptions = res.value;
        });
        getDictList('billingType').then(res => {
          this.currencyOptions = res.value;
        });
      },
      onNext() {
        const data = {
          serviceCountry: this.country.join(';'),
          systemLanguage: this.language,
          paymentCurrency: this.currency
        };

        if (Object.values(data).filter(item => !item).length) {
          this.$message.warning('请先完善資料');
          return false;
        }
        this.$emit('update:userService', data);
        this.$emit('update:activeTabName', 'contactInformation');
        scrollToY();
      }
    }
  };
</script>
<style scoped lang="less">
  .form-item{
    margin-top:11px;
    .item{margin-top:24px;}
    .ps{margin-top:4px;color:#999;font-size:14px;}
    .label{font-size:16px;}
    .ipt-wrap{margin-top:16px;}
    /deep/ .el-textarea__inner{height:160px;padding:10px;}
  }
</style>
