<template>
  <div class="form-wrap">
    <table class="table">
      <!--公司類型-->
      <tr>
        <td class="label" :class="{'is-error': $v.companyType.$error}">{{$t('CompanyType')}}</td>
        <td>
          <el-select class="select" size="small" placeholder=""
                     :class="{'is-error': $v.companyType.$error}"
                     @blur="$v.companyType.$touch()"
                     v-model="companyType">
            <el-option v-for="item in companyTypeOptions"
                       :key="item.code"
                       :label="item.name"
                       :value="item.code">
            </el-option>
          </el-select>
        </td>
      </tr>
      <!--公司名稱-->
      <tr>
        <td class="label" :class="{'is-error': $v.companyName.$error}">{{$t('Company Name')}}：</td>
        <td>
          <input class="ipt" type="text" maxlength="100" autocomplete="off"
                 :placeholder="$t('Company name is used for website presentation and can be modified')"
                 :class="{'is-error': $v.companyName.$error}"
                 @blur="$v.companyName.$touch()"
                 v-model.trim="companyName"/>
        </td>
      </tr>
      <!--法定公司名稱-->
      <tr>
        <td class="label" :class="{'is-error': $v.legalCompanyName.$error}">{{$t('French Company Name')}}：</td>
        <td>
          <input class="ipt" type="text" maxlength="100" autocomplete="off"
                 :placeholder="$t('The legal company name is used for financial settlement and cannot be modified')"
                 :class="{'is-error': $v.legalCompanyName.$error}"
                 @blur="$v.legalCompanyName.$touch()"
                 v-model.trim="legalCompanyName"/>
        </td>
      </tr>
      <!--國家/地區-->
      <tr>
        <td class="label" :class="{'is-error': $v.country.$error}">{{$t('Country')}}/{{$t('Region')}}：</td>
        <td>
          <el-select class="select" filterable size="small" placeholder=""
                     :class="{'is-error': $v.country.$error}"
                     @blur="$v.country.$touch()"
                     v-model="country">
            <el-option v-for="item in countryOptions"
                       :key="item.id"
                       :label="item.name"
                       :value="item.code">
            </el-option>
          </el-select>
        </td>
      </tr>
      <!--省/州-->
      <tr>
        <td class="label" :class="{'is-error': $v.province.$error}">{{$t('Province State')}}</td>
        <td>
          <el-select class="select" filterable size="small" placeholder=""
                     :disabled="!!country&&!provinceOptions.length"
                     :class="{'is-error': $v.province.$error}"
                     @blur="$v.province.$touch()"
                     v-model="province">
            <el-option v-for="item in provinceOptions"
                       :key="item.id"
                       :label="item.name"
                       :value="item.code">
            </el-option>
          </el-select>
        </td>
      </tr>
      <!--城市-->
      <tr>
        <td class="label" :class="{'is-error': $v.city.$error}">{{$t('City')}}：</td>
        <td>
          <el-select class="select" filterable size="small" placeholder=""
                     :disabled="!!country&&!cityOptions.length"
                     :class="{'is-error': $v.city.$error}"
                     @blur="$v.city.$touch()"
                     v-model="city">
            <el-option v-for="item in cityOptions"
                       :key="item.id"
                       :label="item.name"
                       :value="item.code">
            </el-option>
          </el-select>
        </td>
      </tr>
      <!--地址-->
      <tr>
        <td class="label" :class="{'is-error': $v.address.$error}">{{$t('Address')}}：</td>
        <td>
          <input class="ipt" type="text" maxlength="100" autocomplete="off"
                 :class="{'is-error': $v.address.$error}"
                 @blur="$v.address.$touch()"
                 v-model.trim="address"/>
        </td>
      </tr>
      <!--聯絡電話-->
      <tr>
        <td class="label" :class="{'is-error': $v.phoneType.$error || $v.phone.$error}">{{$t('Contact Number')}}：</td>
        <td>
          <el-select class="select w1" size="small" :placeholder="$t('Area code')"
                     :class="{'is-error': $v.phoneType.$error}"
                     @blur="$v.phoneType.$touch()"
                     v-model="phoneType">
            <el-option v-for="item in areaCodeOptions"
                       :key="item.id"
                       :label="item.name"
                       :value="item.code">
            </el-option>
          </el-select>
          <input class="ipt" style="width:200px;margin-left:10px;" type="number" maxlength="20" autocomplete="off"
                 :class="{'is-error': $v.phone.$error}"
                 @blur="$v.phone.$touch()"
                 v-model.trim="phone"/>
        </td>
      </tr>
      <!--Email-->
      <tr>
        <td class="label" :class="{'is-error': $v.email.$error}">{{$t('Email')}}：</td>
        <td>
          <input class="ipt" type="text" maxlength="100" autocomplete="off"
                 :class="{'is-error': $v.email.$error}"
                 @blur="$v.email.$touch()"
                 v-model.trim="email"/>
        </td>
      </tr>
      <!--公司網站-->
      <tr>
        <td class="label">{{$t('Company Website')}}({{$t('Optional')}})：</td>
        <td>
          <input class="ipt" type="text" maxlength="100" autocomplete="off" v-model.trim="websiteUrl"/>
        </td>
      </tr>
      <!--是否分公司-->
      <tr>
        <td class="label" :class="{'is-error': $v.isBranchOffice.$error}">{{$t('isBranchOffice')}}：</td>
        <td style="vertical-align:middle">
          <radio class="medium" v-model="isBranchOffice" label="Y">{{$t('Yes')}}</radio>
          <radio class="medium" v-model="isBranchOffice" label="N">{{$t('No')}}</radio>
        </td>
      </tr>
      <!--公司簡介-->
      <tr>
        <td class="label" :class="{'is-error': $v.introduction.$error}">{{$t('Company Profile')}}：</td>
        <td>
          <el-input type="textarea"
                    placeholder=""
                    maxlength="1000"
                    show-word-limit
                    :class="{'is-error': $v.introduction.$error}"
                    @blur="$v.introduction.$touch()"
                    v-model="introduction">
          </el-input>
        </td>
      </tr>
      <tr>
        <td></td>
        <td>
          <div class="actions">
            <button class="button" @click="onNext">{{$t('NextStep')}}</button>
          </div>
        </td>
      </tr>
    </table>
  </div>
</template>
<script>
  import {required, requiredIf, email} from 'vuelidate/lib/validators';
  import {getDictList, getProvinceList, getCitys} from '../../../../api/admin-common';
  import {scrollToY} from '../../../../utils';
  import Radio from '../../../../components/Radio';

  export default {
    name: 'BasicInformation',
    components: {Radio},
    props: {
      userInfo: Object,
      activeTabName: String,
      countryOptions: Array,
      areaCodeOptions: Array
    },
    data() {
      return {
        companyTypeOptions: [],
        companyType: '',
        companyName: '',
        legalCompanyName: '',
        country: '',
        provinceOptions: [],
        province: '',
        cityOptions: [],
        city: '',
        address: '',
        phoneType: '',
        phone: '',
        email: '',
        websiteUrl: '',
        isBranchOffice: 'N',
        introduction: ''
      };
    },
    validations: {
      companyType: {required},
      companyName: {required},
      legalCompanyName: {required},
      country: {required},
      province: {
        required: requiredIf((datas) => {
          return !!datas.provinceOptions.length;
        })
      },
      city: {
        required: requiredIf((datas) => {
          return !!datas.cityOptions.length;
        })
      },
      address: {required},
      phoneType: {required},
      phone: {required},
      email: {required, email},
      isBranchOffice: {required},
      introduction: {required}
    },
    created() {
      this.getDicts();
    },
    methods: {
      // 获取数据字典
      getDicts() {
        getDictList('companyType').then(res => {
          this.companyTypeOptions = res.value || [];
        });
      },
      // 获取省列表
      getProvinceList(value) {
        this.provinceOptions = [];
        this.province = '';
        getProvinceList(value).then(res => {
          this.provinceOptions = res.value || [];
          if (!this.provinceOptions.length) {
            this.getCityList();
          }
        });
      },
      // 获取城市列表
      getCityList() {
        this.cityOptions = [];
        this.city = '';
        // 获取城市字典
        getCitys(this.country, this.province).then(res => {
          this.cityOptions = res.value || [];
        });
      },
      onNext() {
        const data = {
          companyType: this.companyType,
          companyName: this.companyName,
          legalCompanyName: this.legalCompanyName,
          country: this.country,
          province: this.province,
          city: this.city,
          address: this.address,
          phoneType: this.phoneType,
          phone: this.phone,
          email: this.email,
          websiteUrl: this.websiteUrl,
          isBranchOffice: this.isBranchOffice,
          introduction: this.introduction
        };

        this.$v.$touch();
        if (this.$v.$invalid) {
          this.$message.warning(this.$t('Please complete the information before submitting'));
          this.$nextTick(() => {
            if (document.querySelector('.is-error')) {
              scrollToY(undefined, document.querySelector('.is-error').offsetTop);
            }
          });
          return false;
        }

        this.$emit('update:userInfo', data);
        this.$emit('update:activeTabName', 'business');
        scrollToY();
      }
    },
    watch: {
      country(value) {
        this.getProvinceList(value);
      },
      province(value) {
        this.getCityList();
      }
    }
  };
</script>
<style scoped lang="less">
  .form-wrap{
    margin-top:18px;
    .table{
      width:100%;
      td{padding:12px 0;}
    }
    .label{width:112px;line-height:34px;white-space:nowrap;font-size:16px;}
    .ipt{
      width:330px;height:32px;padding:0 10px;color:#321908;font-size:16px;border:1px solid #d9d9d9;border-radius:4px;
      &:focus{border-color:#ff6f61;}
    }
    /deep/ .el-textarea__inner{height:200px;padding:10px;}
  }
</style>
