<template>
  <success v-if="currentName==='success'"/>
  <ServiceAgreement :active-tab-name.sync="currentName" v-else-if="currentName==='agreement'"/>
  <div class="main" v-else>
    <h1 class="h1">{{$t('RegisterAsOurMerchant')}}</h1>
    <tab-nav :panes="tabNav"/>
    <basic-information :active-tab-name.sync="currentName"
                       :user-info.sync="userInfo"
                       :country-options="countryOptions"
                       :area-code-options="areaCodeOptions"
                       v-show="currentName==='basicInformation'"/>
    <business :active-tab-name.sync="currentName"
              :qualification.sync="qualification"
              v-show="currentName==='business'"/>
    <products-services :active-tab-name.sync="currentName"
                       :user-service.sync="userService"
                       :country-options="countryOptions"
                       v-show="currentName==='productsServices'"/>
    <contact-information :active-tab-name.sync="currentName"
                         :user-contacts.sync="userContacts"
                         :area-code-options="areaCodeOptions"
                         v-show="currentName==='contactInformation'"/>
    <protocol :datas="postData"
              :active-tab-name.sync="currentName"
              v-show="currentName==='protocol'"/>
  </div>
</template>
<script>
  import Vue from 'vue';
  import Vuelidate from 'vuelidate';
  import TabNav from '../../../components/Tabs/TabNav';
  import BasicInformation from './components/BasicInformation';
  import Business from './components/Business';
  import ProductsServices from './components/ProductsServices';
  import ContactInformation from './components/ContactInformation';
  import Protocol from './components/Protocol';
  import Success from './components/Success';
  import ServiceAgreement from './components/ServiceAgreement';
  import {getDictList, getCountryList} from '../../../api/admin-common';

  Vue.use(Vuelidate);
  export default {
    name: 'AdminRegistered',
    data() {
      return {
        tabNav: [
          {name: 'basicInformation', label: this.$t('CompanyBasicInformation')},
          {name: 'business', label: this.$t('BusinessRegistration')},
          {name: 'productsServices', label: this.$t('ProductsAndServices')},
          {name: 'contactInformation', label: this.$t('ContactInformation')},
          {name: 'protocol', label: this.$t('TermsOfCooperation')}
        ],
        currentName: 'agreement',
        countryOptions: [],
        areaCodeOptions: [],
        userInfo: {},
        qualification: {},
        userService: {},
        userContacts: []
      };
    },
    computed: {
      postData: function () {
        return {
          userInfo: this.userInfo,
          qualification: this.qualification,
          userService: this.userService,
          userContacts: this.userContacts
        };
      }
    },
    components: {
      ServiceAgreement,
      TabNav,
      BasicInformation,
      Business,
      ProductsServices,
      ContactInformation,
      Protocol,
      Success
    },
    created() {
      getCountryList().then(res => {
        this.countryOptions = res.value;
      });
      getDictList('phonePrefix').then(res => {
        this.areaCodeOptions = res.value;
      });
    }
  };
</script>
<style scoped lang="less">
  .main{
    width:900px;margin:0 auto;
    .h1{margin-top:30px;color:#321908;font-size:24px;}
    /deep/ .tab-nav .item{width:20%;pointer-events:none;}
    /deep/ .actions{margin-top:40px;text-align:center;}
    /deep/ .select{
      width:330px;vertical-align:top;tab-nav
      .el-input__inner{font-size:16px;}
      &.w1{width:120px;}
    }
  }
</style>
