<template>
  <div class="business-wrap">
    <!--獲許經營範圍-->
    <div class="form-item">
      <div class="label">{{$t('Licensed business scope')}}：</div>
      <div class="value">
        <div class="cl">
          <template v-for="item of businessScopeOptions">
            <template v-if="item.code!=='qita'">
              <checkbox v-model="item.isSelected" :key="item.code">
                <div class="checkbox-label">{{item.name}}</div>
              </checkbox>
            </template>
            <div style="padding-top:16px;clear:both;" :key="item.code" v-else>
              <checkbox style="margin-right:8px;" v-model="item.isSelected">
                <div class="checkbox-label">{{item.name}}</div>
              </checkbox>
              <input class="ipt w1" type="text" maxlength="100" :placeholder="$t('PleaseEnter')" autocomplete="off"
                     v-model.trim="otherRange"/>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="cl">
      <div class="fl">
        <div class="upload-item">
          <!--營業執照-->
          <upload-file :title="$t('Business license')" :file-title.sync="licenseFileName" v-model="licenseFileUrl"/>
        </div>
        <!--牌照號碼-->
        <div class="form-item m-t">
          <div class="label">{{$t('License number')}}：</div>
          <div class="value">
            <input class="ipt full" type="text" maxlength="100" :placeholder="$t('PleaseEnter')" autocomplete="off"
                   v-model.trim="licenseCode"/>
          </div>
        </div>
        <div class="form-item m-t">
          <!--到期日-->
          <div class="label">{{$t('Expiry date')}}：</div>
          <div class="value">
            <div class="fl">
              <radio v-model="isLicenseLasting" label="N">
                <el-date-picker class="select"
                                type="date"
                                size="small"
                                :picker-options="pickerOptions"
                                value-format="yyyy-MM-dd"
                                :placeholder="$t('PleaseChoose')"
                                v-model="licenseEndTime">
                </el-date-picker>
              </radio>
            </div>
            <div class="fr">
              <!--永久有效-->
              <radio class="medium" v-model="isLicenseLasting" label="Y">{{$t('Permanent validity')}}</radio>
            </div>
          </div>
        </div>
      </div>
      <div class="fr">
        <!--經營許可證-->
        <upload-file :title="$t('Permit foroperation')" :file-title.sync="permitFileName" v-model="permitFileUrl"/>
        <div class="form-item m-t">
          <!--牌照號碼-->
          <div class="label">{{$t('License number')}}：</div>
          <div class="value">
            <input class="ipt full" type="text" maxlength="100" :placeholder="$t('PleaseEnter')" autocomplete="off"
                   v-model.trim="permitCode"/>
          </div>
        </div>
        <div class="form-item m-t">
          <!--到期日-->
          <div class="label">{{$t('Expiry date')}}：</div>
          <div class="value">
            <div class="fl">
              <radio v-model="isPermitLasting" label="N">
                <el-date-picker class="select"
                                type="date"
                                size="small"
                                :picker-options="pickerOptions"
                                value-format="yyyy-MM-dd"
                                :placeholder="$t('PleaseChoose')"
                                v-model="licensePermitTime">
                </el-date-picker>
              </radio>
            </div>
            <div class="fr">
              <!--永久有效-->
              <radio class="medium" v-model="isPermitLasting" label="Y">{{$t('Permanent validity')}}</radio>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="actions">
      <button class="button m-r" @click="$emit('update:activeTabName', 'basicInformation')">
        {{$t('PreviousStep')}}
      </button>
      <button class="button" @click="onNext">{{$t('NextStep')}}</button>
    </div>
  </div>
</template>
<script>
  import Checkbox from '../../../../components/Checkbox';
  import Radio from '../../../../components/Radio';
  import UploadFile from './UploadFile';
  import {getDictList} from '../../../../api/admin-common';

  export default {
    name: 'Business',
    components: {UploadFile, Radio, Checkbox},
    props: {
      qualification: Object,
      activeTabName: String
    },
    data() {
      return {
        businessScopeOptions: [],
        pickerOptions: {
          disabledDate(time) {
            return time.getTime() < Date.now();
          }
        },
        businessScope: '',
        otherRange: '',
        // 营业执照
        licenseFileName: '',
        licenseFileUrl: '',
        licenseCode: '',
        licenseEndTime: '',
        isLicenseLasting: 'N',
        // 經營許可證
        permitFileName: '',
        permitFileUrl: '',
        permitCode: '',
        licensePermitTime: '',
        isPermitLasting: 'N'
      };
    },
    created() {
      this.getDicts();
    },
    methods: {
      // 获取数据字典
      getDicts() {
        getDictList('rangeCode').then(res => {
          this.businessScopeOptions = res.value || [];
        });
      },
      onNext() {
        const rangeCodes = this.businessScopeOptions.filter(item => item.isSelected).map(item => item.code);
        const rangeNames = this.businessScopeOptions.filter(item => item.isSelected).map(item => item.name);
        const data = {
          rangeCode: rangeCodes.join(';'),
          rangeName: rangeNames.join(';'),
          otherRange: this.otherRange,
          licenseFileName: this.licenseFileName,
          licenseFileUrl: this.licenseFileUrl,
          licenseCode: this.licenseCode,
          isLicenseLasting: this.isLicenseLasting,
          licenseEndTime: this.isLicenseLasting === 'Y' ? '' : this.licenseEndTime,
          permitFileName: this.permitFileName,
          permitFileUrl: this.permitFileUrl,
          permitCode: this.permitCode,
          isPermitLasting: this.isPermitLasting,
          licensePermitTime: this.isPermitLasting === 'Y' ? '' : this.licensePermitTime
        };

        if (!data.rangeCode) {
          this.$message.warning(this.$t('Please choose', [this.$t('Licensed business scope')]));
          return false;
        }
        if (!data.licenseFileUrl) {
          this.$message.warning(this.$t('Please upload', [this.$t('Business license')]));
          return false;
        }
        if (!data.permitFileUrl) {
          this.$message.warning(this.$t('Please upload', [this.$t('Permit foroperation')]));
          return false;
        }
        if (!data.licenseCode || !data.permitCode) {
          this.$message.warning(this.$t('Please fill out', [this.$t('License number')]));
          return false;
        }
        if ((data.isLicenseLasting === 'N' && !data.licenseEndTime) || (data.isPermitLasting === 'N' && !data.licensePermitTime)) {
          this.$message.warning(this.$t('Please fill out', [this.$t('Expiry date')]));
          return false;
        }

        this.$emit('update:qualification', data);
        this.$emit('update:activeTabName', 'productsServices');
      }
    }
  };
</script>
<style scoped lang="less">
  .business-wrap{
    padding-top:30px;
    .form-item{
      display:flex;
      &.m-t{margin-top:16px;}
      .label{line-height:32px;white-space:nowrap;font-size:16px;}
      .value{flex:1;line-height:32px;}
      .ipt{
        width:200px;height:32px;padding:0 10px;color:#321908;font-size:16px;border:1px solid #d9d9d9;border-radius:4px;
        &:focus{border-color:#ff6f61;}
        &.full{width:100%;}
        &.w1{width:300px;}
      }
      .select{width:210px;}
      .checkbox-label{font-size:16px;}
      /deep/ .checkbox-wrap{float:left;margin-top:8px;margin-right:24px;}
      /deep/ .radio{margin-right:0;}
    }
  }
</style>
