<template>
  <div class="layout">
    <h1 class="h1">{{$t('Confirm successful submission of application')}}</h1>
    <div class="p1">
      {{$t('p1')}}<br/>
      {{$t('p2')}}<br/>
      {{$t('p3')}}
    </div>
    <div class="p2">{{$t('If you have any enquiries, please contact us')}}：info@unutravel.com</div>
    <router-link class="btn-submit" :to="{name:'Home'}">{{$t('Back to Home')}}</router-link>
  </div>
</template>
<script>
  export default {
    name: 'Success',
    i18n: {
      messages: {
        'zh-cn': {
          p1: '感谢贵公司的关注与支持，阁下已顺利完成商户登记手续，',
          p2: 'UNU Travel商业客户的认证手续约需时5个工作天。',
          p3: 'UNU Travel承诺竭力为贵公司提供超卓的商户体验﹗'
        },
        'zh-hk': {
          p1: '感謝貴公司的關注與支持，閣下已順利完成商戶登記手續，',
          p2: 'UNU Travel商業客戶的認證手續約需時5個工作天。',
          p3: 'UNU Travel承諾竭力為貴公司提供超卓的商戶體驗﹗'
        },
        'en': {
          p1: '感謝貴公司的關注與支持，閣下已順利完成商戶登記手續，',
          p2: 'UNU Travel商業客戶的認證手續約需時5個工作天。',
          p3: 'UNU Travel承諾竭力為貴公司提供超卓的商戶體驗﹗'
        }
      }
    }
  };
</script>
<style scoped lang="less">
  .layout{text-align:center}
  .h1{margin-top:60px;font-size:36px;font-weight:bold;}
  .p1{margin-top:30px;font-size:24px;}
  .p2{margin-top:30px;font-size:16px;}
  .btn-submit{display:block;width:132px;line-height:42px;margin:60px auto;text-align:center;color:#ff6f61;font-size:16px;border:1px solid #ff6f61;border-radius:4px;}
</style>
