<template>
  <div class="layout agreement">
    <template v-if="language==='zh-cn'">
      <h2 class="h2">UNU Travel 免费试用服务条款</h2>
      <p class="b" style="text-indent:0">使用 UNU Travel 免费试用服务条款的客户应阅读及同意接受以下有关条款：</p>
      <p>1. 本《UNU Travel 免费试用服务条款》为UNU TECHNOLOGY LIMITED 又一科技有限公司与您之间所签订的协议，当您访问 UNU Travel 的网站或使用本服务，表示您已同意接受本服务条款和服务使用守则的约束；</p>
      <p>2. 试用期以 UNU Travel 发送的确认启用免费试用服务电子邮件内说明的日期为准；</p>
      <p>3. 试用服务只供用户作测试及评估用途，并不适用或授权作任何类型的商业用途或提供第三方使用；</p>
      <p>4. UNU Travel所提供的免费试用服务，直至 (a)试用期结束 或 (b)提前订购正式服务；</p>
      <p>5. 试用期将于 UNU Travel 发送的确认启用免费试用服务电子邮件内说明的日期到达后自动结束。如果需要延长试用期，请与我们的营业部联络；</p>
      <p>6. UNU Travel 对免费试用服务的客户不作任何保证承诺，包括服务满意、服务水平等；</p>
      <p>7. UNU Travel 对免费试用服务未有提供标准支援服务；</p>
      <p>8. UNU Travel 对于通过该服务提供予使用者的任何资讯、软体和服务，不保证其准确性、正确性、可靠性或其他；</p>
      <p>9. UNU Travel 保留暂停，限制和随时终止试用服务的权利，而不需任何理由或另行通知使用者；</p>
      <p>10. 如需要将试用服务升级至标准服务计划，请于试用到期前一天通知我们的营业代表；</p>
      <p>11. UNU Travel 对使用者于试用期间任何种类的损失概不负责；</p>
      <p>12. 试用期完结后，伺服器内所有数据资料有机会将被永久删除，并且不会被恢复，但 UNU Travel 会尽量避免删除相关数据，使用者需自行备份所有资料；</p>
      <p>13. UNU Travel 暂时只提供有限度试用，全功能平台视乎最新情况而开放；</p>
      <p>14. 申请试用服务需要提交包括但不限于有效的商业登记证及旅行代理商牌照，并只限申请一次；</p>
      <p>15. 试用期内与本次试用服务相关的档案、信息及资料，未经允许，禁止使用者对外传播；</p>
      <p>16. 任何因违反服务条款和服务使用守则已被暂停或终止的客户或账户，自动丧失使用此服务之权利；</p>
      <p>17. UNU Travel 将保留任一切最终决定权。</p>
      <p>更新日期: 2022年9月30日</p>
    </template>
    <template v-else>
      <h2 class="h2">UNU Travel 免費試用服務條款</h2>
      <p class="b" style="text-indent:0">使用 UNU Travel 免費試用服務條款的客戶應閱讀及同意接受以下有關條款：</p>
      <p>1. 本《UNU Travel 免費試用服務條款》為 UNU TECHNOLOGY LIMITED 又一科技有限公司 與您之間所簽訂的協議，當您訪問 UNU Travel 的網站或使用本服務，表示您已同意接受本服務條款和服務使用守則的約束；</p>
      <p>2. 試用期以 UNU Travel 發送的確認啟用免費試用服務電子郵件內說明的日期為準；</p>
      <p>3. 試用服務只供用戶作測試及評估用途，並不適用或授權作任何類型的商業用途或提供第三方使用；</p>
      <p>4. UNU Travel 所提供的免費試用服務，直至 (a)試用期結束 或 (b)提前訂購正式服務；</p>
      <p>5. 試用期將於 UNU Travel 發送的確認啟用免費試用服務電子郵件內說明的日期到達後自動結束。如果需要延長試用期，請與我們的營業部聯絡；</p>
      <p>6. UNU Travel 對免費試用服務的客戶不作任何保證承諾，包括服務滿意、服務水平等；</p>
      <p>7. UNU Travel 對免費試用服務未有提供標準支援服務；</p>
      <p>8. UNU Travel 對於通過該服務提供予使用者的任何資訊、軟體和服務，不保證其準確性、正確性、可靠性或其他；</p>
      <p>9. UNU Travel 保留暫停，限制和隨時終止試用服務的權利，而不需任何理由或另行通知使用者；</p>
      <p>10. 如需要將試用服務升級至標準服務計劃，請於試用到期前一天通知我們的營業代表；</p>
      <p>11. UNU Travel 對使用者於試用期間任何種類的損失概不負責；</p>
      <p>12. 試用期完結後，伺服器內所有數據資料有機會將被永久刪除，並且不會被恢復，但 UNU Travel 會盡量避免刪除相關數據，使用者需自行備份所有資料；</p>
      <p>13. UNU Travel 暫時只提供有限度試用，全功能平台視乎最新情況而開放；</p>
      <p>14. 申請試用服務需要提交包括但不限於有效的商業登記證及旅行代理商牌照，並只限申請一次；</p>
      <p>15. 試用期內與本次試用服務相關的檔案、信息及資料，未經允許，禁止使用者對外傳播；</p>
      <p>16. 任何因違反服務條款和服務使用守則已被暫停或終止的客戶或賬戶，自動喪失使用此服務之權利；</p>
      <p>17. UNU Travel 將保留任一切最終決定權。</p>
      <p>更新日期: 2022年9月30日</p>
    </template>
    <div class="actions">
      <checkbox v-model="isAgree">
        <div class="checkbox-label">{{$t('I have read and agree to the above terms of use')}}</div>
      </checkbox>
      <div>
        <!--取消-->
        <a class="button" @click="onCancel">{{ $t('Cancel') }}</a>
        <!--開始註冊-->
        <a class="button button-primary" :disabled="!isAgree" @click="onNext">{{ $t('Start Register') }}</a>
      </div>
    </div>
  </div>
</template>
<script>
import Checkbox from '../../../../components/Checkbox';
import {scrollToY} from '@/utils';

export default {
  name: 'ServiceAgreement',
  i18n: {
    messages: {
      'zh-cn': {
        registered: '开始注册'
      },
      'zh-hk': {
        registered: '開始註冊'
      },
      'en': {
        registered: 'Sign Up Now'
      }
    }
  },
  props: {
    activeTabName: String
  },
  components: {Checkbox},
  data() {
    return {
      language: '',
      isAgree: false
    };
  },
  mounted() {
    // Bypass Agreement
    this.$emit('update:activeTabName', 'basicInformation');
    //
    this.language = this.$i18n.locale;
  },
  methods: {
    onCancel() {
      this.$router.go(-1);
    },
    onNext() {
      this.$emit('update:activeTabName', 'basicInformation');
      scrollToY();
    }
  }
};
</script>
<style scoped lang="less">
.agreement{
  text-align:justify;
  .h2{padding:60px 0 40px;text-align:center;font-size:22px;font-weight:bold;}
  p{margin-top:10px;line-height:1.8;text-indent:2em;font-size:16px;}
  .actions{
    margin-top:40px;text-align:center;
    .button{margin:20px 10px 0;}
  }
  .checkbox-wrap{display:inline-block;vertical-align:top;}
}
</style>
